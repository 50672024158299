/**
 * Variable declarations
 */
$btn-color: #eceff1; 
$btn-size : 3.75em;
$duration : 300ms;
$easing   : cubic-bezier(0, 0, .2, 1);
$half-size: ($btn-size / 2);

// Hide iOS Play Buttons
*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

.video-on {
  transition: all .5s ease!important;
  opacity:0!important;
}

/**
 * The actual play button
 */
.play-btn {
  perspective:1000;
  backface-visibility:visible;
	transition: all .5s ease;
  filter:drop-shadow(0 0 10px rgba(0,0,0,0.8));
  /** Positioning */
  &:hover,
  &:focus {
    // filter:drop-shadow(0 0 10px rgba($white,0.75));
    transform:scale(0.95);
    outline:none;
  }

  position    : absolute;
  perspective:1000;
  backface-visibility: visible;
  z-index: 10;
  left        : 51.5%;
  top         : 50%;
  margin-left : -$half-size;
  margin-top  : -$half-size;

  @media (max-width: $screen-sm-min) {
    top         : 45%;
  }
  
  /** Sizing */
  width       : $btn-size;
  height      : $btn-size;
  
  /** Border styling */
  border-style: solid;
  border-color: transparent $btn-color transparent $btn-color;
  border-width: $half-size 0 $half-size $btn-size;

  
  &.stop,
  &.to-play {
  	
    /** Animate morphing */
    transition  : transform           $duration $easing,
                  border-top-width    $duration $easing,
                  border-bottom-width $duration $easing;
  
  }
  
  &.stop {
  	
    /** Morph to a stop button */
    transform   : rotate(90deg);
    border-width: 0 0 0 $btn-size;
    
  }
  
  &.to-play {
    
    /** Morph back from a stop button to a play button */
    transform   : rotate(180deg);
    border-width: $half-size $btn-size $half-size 0;
    
  }
  
}