// Grid system
// .main {
//   @include make-sm-column($main-sm-columns);
//   .sidebar-primary & {
//     @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
//   }
// }
// .sidebar {
//   @include make-sm-column($sidebar-sm-columns);
// }

.grid-sizer {
	width:100%;
}

// .grid {

// 	@include clearfix();

// 	&--productions {

// 		clear:both;

// 		.production {

// 			text-align: center;
// 			@include make-xs-column(12);
// 			@include make-sm-column(6);
// 			margin-bottom: 1em;
// 			perspective:1000;
// 			-webkit-backface-visibility: visible;
// 			backface-visibility: visible;

// 			.image-block {
// 				padding-bottom: 56.25%;
// 				position: relative;
// 				perspective:1000;
// 				-webkit-backface-visibility: visible;
// 				backface-visibility: visible;
				
// 				&:before {
// 					content:"";
// 					bottom:0;
// 					left:0;
// 					height:100%;
// 					display: block;
// 					z-index: 1;
// 					position:absolute;
// 					width:100%;
// 					transition: opacity .75s ease;
// 			    	background: linear-gradient( 180deg, rgba(#111,0.1), rgba(#111,0.9) );
// 			    	// background: $overlay-blue;
// 			    	// mix-blend-mode:color;
// 			    	display: none;

// 			    	@media (max-width: $screen-md-min) {
// 						display: block;
// 			    	}
// 				}				

// 				&:after {
// 					content:"";
// 					bottom:0;
// 					left:0;
// 					height:100%;
// 					display: block;
// 					z-index: 1;
// 					position:absolute;
// 					width:100%;
// 					transition: opacity .75s ease;
// 			    	background: linear-gradient( 180deg, rgba($overlay-blue,0.9), rgba($overlay-blue,0.9) );
// 			    	// background: $overlay-blue;
// 			    	// mix-blend-mode:color;
// 			    	opacity:0;

// 			    	@media (max-width: $screen-md-min) {
// 			    		opacity:0;
// 			    	}
// 				}
// 			}
			
// 			.no-image {
// 				&:before {
// 					display: none;
// 				}
// 				+ span {
// 					opacity:1;
// 					top:40%;
// 					transform:translateY(0);
// 				}
// 				&:hover {
// 					+ span {
// 						transform:translateY(5px);
// 					}
// 				}
// 			}
			
// 			span {
		    	
// 		    	@media (max-width: $screen-md-min) {
// 		    		opacity:1;
// 		    		transform: translateY(.15em);
// 		    	}

// 			    position: absolute;
// 			    z-index: 100;
// 			    top: 40%;
// 			    width:93%;
// 			    text-align: center;
// 			    display: block;
// 			    @include heavyText();
// 			    color: #f5f5f5;
// 			    font-size: 8vw;
// 			    transform: translateY(-50%);
// 			    line-height: 1;
			    
// 			    transition:transform .75s ease, opacity .75s ease;
// 				opacity:0;

// 			    @media (min-width: $screen-xs-min) {
// 			    	font-size: 2em;
// 			    }
// 			    @media (min-width: $screen-md-min) {
// 			    	top: 42%;
// 			    	font-size: 2.5em;
// 			    	width: 95%;
// 			    }
// 			}

// 			&:first-of-type, &.supersize-class {
				
// 				@include make-sm-column(12);

// 				.image-block {
// 					@media (min-width:$screen-sm-min) {
// 						padding-bottom: 46.25%;
// 					}
// 				}

// 			    @media (min-width: $screen-sm-min) {
// 			    	font-size: 1.5em;
// 			    }
			    
// 			    @media (min-width: $screen-md-min) {
// 			    	font-size: 2em;
// 			    }

// 			}

// 			&:hover {

// 				.image-block {

// 					&:after {
// 						opacity:1;
// 						transition: all .5s ease-out;
// 					}

// 					+ span {
// 						opacity:1;
// 						transform: translateY(0);
// 					}
					
// 				}

// 			}


// 		}

// 	}

// }

.grid--productions, .grid:after {
  clear: both;
}

.grid--productions {
  .grid-sizer, .production {
    text-align: center;
    overflow: hidden;
    float: left;
    width: 100%;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    padding: 0;
    -webkit-perspective: 1000;
    perspective: 1000;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
  }
  .grid-sizer .image-block, .production .image-block {
    padding-bottom: 56.25%;
    position: relative;
    -webkit-perspective: 1000;
    perspective: 1000;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-transition: -webkit-transform 5s ease-in-out;
    transition: transform 5s ease-in-out;
  }
  .grid-sizer .image-block:before, .production .image-block:before {
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    position: absolute;
    width: 100%;
    -webkit-transition: opacity .75s ease;
    transition: opacity .75s ease;
    background: -webkit-linear-gradient(270deg, hsla(0, 0%, 7%, 0.1), hsla(0, 0%, 7%, 0.9));
    background: linear-gradient(180deg, hsla(0, 0%, 7%, 0.1), hsla(0, 0%, 7%, 0.9));
    display: block;
    opacity: 0;
  }
  .grid-sizer .image-block.no-image:before, .production .image-block.no-image:before {
    display: none;
  }
  .grid-sizer .image-block:after, .production .image-block:after {
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    display: block;
    z-index: 1;
    position: absolute;
    width: 100%;
    -webkit-transition: opacity .75s ease;
    transition: opacity .75s ease;
    mix-blend-mode: exclusion;
    opacity: 0;
  }
  .grid-sizer .image-block.no-image + span, .production .image-block.no-image + span {
    -webkit-transition: translateY(0);
    transition: translateY(0);
    opacity: 1;
  }
  .grid-sizer .image-block.no-image:hover + span, .production .image-block.no-image:hover + span {
    -webkit-transition: translateY(-20px);
    transition: translateY(-20px);
  }
  .grid-sizer span, .production span {
    position: absolute;
    z-index: 100;
    top: 50%;
    width: 100%;
    text-align: center;
    display: block;
    font-weight: 700;
    color: #fff3e7;
    font-size: 8vw;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 1;
    -webkit-transition: -webkit-transform .75s ease,opacity .75s ease;
    transition: transform .75s ease,opacity .75s ease;
    opacity: 0;
  }
  .grid-sizer {
    &.supersize-class, &:first-of-type {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      padding: 0;
    }
  }
  .production {
    &.supersize-class, &:first-of-type {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      padding: 0;
    }
  }
  .grid-sizer.unsupersize-class span, .production.unsupersize-class span {
    color: #fff;
  }
  .grid-sizer:hover .image-block, .production:hover .image-block {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .grid-sizer:hover .image-block {
    + span, &:after, &:before {
      opacity: 1;
    }
  }
  .production:hover .image-block {
    + span, &:after, &:before {
      opacity: 1;
    }
  }
  .grid-sizer--awards, .production--awards {
    padding: 0 !important;
  }
  .grid-sizer--awards span, .production--awards span {
    display: none;
    opacity: 1;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    margin-left: 0;
  }
  .grid-sizer--awards .image-block {
    &:after, &:before {
      display: none;
    }
  }
  .production--awards .image-block {
    &:after, &:before {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .grid--productions {
    .grid-sizer, .production {
      float: left;
      width: 50%;
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 992px) {
  .grid--productions {
    .grid-sizer .image-block:before, .production .image-block:before {
      opacity: 1;
    }
  }
}

@media (max-width: 992px) {
  .grid--productions {
    .grid-sizer .image-block:after, .production .image-block:after {
      opacity: 1;
    }
  }
}

@media (max-width: 992px) {
  .grid--productions {
    .grid-sizer span, .production span {
      opacity: 1;
    }
  }
}

@media (min-width: 992px) {
  .grid--productions {
    .grid-sizer span, .production span {
      top: 50%;
      font-size: 2.5em;
      width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .grid--productions {
    .grid-sizer {
      &.supersize-class, &:first-of-type {
        float: left;
        width: 100%;
      }
    }
    .production {
      &.supersize-class, &:first-of-type {
        float: left;
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) {
  .grid--productions {
    .grid-sizer {
      &.supersize-class .image-block, &:first-of-type .image-block {
        padding-bottom: 46.25%;
      }
    }
    .production {
      &.supersize-class .image-block, &:first-of-type .image-block {
        padding-bottom: 46.25%;
      }
    }
  }
}

@media (min-width: 768px) {
  .grid--productions {
    .grid-sizer {
      &.supersize-class, &:first-of-type {
        font-size: 1.5em;
      }
    }
    .production {
      &.supersize-class, &:first-of-type {
        font-size: 1.5em;
      }
    }
  }
}

@media (min-width: 992px) {
  .grid--productions {
    .grid-sizer {
      &.supersize-class, &:first-of-type {
        font-size: 2em;
      }
    }
    .production {
      &.supersize-class, &:first-of-type {
        font-size: 2em;
      }
    }
  }
}

@media (min-width: 768px) {
  .grid--productions {
    .grid-sizer.unsupersize-class, .production.unsupersize-class {
      padding: 0 1em;
    }
  }
}

@media (min-width: 768px) {
  .grid--productions {
    .grid-sizer.unsupersize-class span, .production.unsupersize-class span {
      font-size: 2.5em;
      margin-left: -15px;
    }
  }
}

@media (min-width: 992px) {
  .grid--productions {
    .grid-sizer--awards span, .production--awards span {
      font-size: 1.5em;
    }
  }
}