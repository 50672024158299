.btn--awards {
    background: #323e48;
    color: #fff;
    padding: .5em 1em;
    vertical-align: baseline;
    margin: 1em 1em 0 0;
    display: inline-block;
    border-radius: 0;
    width: 100%;
    font-weight: 700;
    text-align: center;
    border-radius:2px;
}

.btn--awards:focus,.btn--awards:hover {
    color: #fff;
    background: #ff8400
}

.btn--awards:before {
    content: "";
    background: url(../images/award.svg) no-repeat 50%;
    width: 1.75em;
    height: 1.75em;
    display: inline-block;
    vertical-align: -.5em;
    position: relative;
    margin-right: .4em
}

.awards__block {
  background: #111;
  color: #fff;
  font-size: 0.9em;
  h1 {
    margin: -1.85em 0 .75em;
    z-index: 10;
    position: relative;
  }
  h3 {
    margin: 0 0 .75em;
    font-size: 1.25em;
    line-height: 1.2;
    &:before {
      content: "";
      display: block;
      width: 5rem;
      background: #373737;
      height: 1px;
      margin: 0 auto 0.75em;
    }
    &:first-of-type:before {
      display: none;
    }
  }
  .award__item {
    display: inline-block;
    width: auto;
    vertical-align: top;
    margin: 0;
    .laurel_icon {
      width: 1.75em;
      height: 1.75em;
      display: block;
      margin: 0 auto;
      fill: #ff8400;
      font-size: 1.6em;
    }
    p {
      font-size: 1em;
      line-height: 1.3;
      margin: 0 1em 1em;
      span {
        color: inherit;
        display: block;
        font-size: inherit;
        font-weight: inherit;
        -webkit-transform: none;
        transform: none;
        position: static;
        line-height: 1.3;
      }
    }
    strong {
      line-height: 1.5;
      display: block;
    }
  }
}

@media (min-width: 768px) {
  .awards__block {
    font-size: 0.7em;
  }
}

@media (min-width: 992px) {
  .awards__block {
    font-size: 0.55em;
  }
}

.awards__block--listing {
  padding: 1em 1em 2em;
  margin: 0;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 4em;
    left: 0;
    width: 100%;
    top: -4em;
    background: hsla(0, 0%, 7%, 0.7);
  }
}

@media (min-width: 768px) {
  .awards__block--listing {
    margin: 0 0 3em;
  }
}

.awards__block--overlay {
  font-size: 1em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  min-height: 100%;
  color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: scroll;
  display: none;
  .close-icon {
    font-size: 1em;
    position: absolute;
    top: 1.5em;
    right: 1.25em;
    line-height: 1;
    opacity: .5;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease;
    z-index: 10000;
    &:hover {
      opacity: 0.9;
    }
    &:before {
      content: "\2715";
      color: #fff;
      font-size: 2em;
      padding: 0;
      position: relative;
    }
  }
  p {
    color: #fff;
    &:before {
      content: "";
      display: block;
      width: 1.75em;
      height: 1.75em;
      margin: 0 auto;
      background: url(/wp-content/themes/forge/dist/images/award-brand.svg) no-repeat 50%;
      font-size: 1.6em;
    }
    .nomination:after, .winner:after {
      content: "Won";
      display: block;
      margin: 0 auto;
      color: inherit;
      font-weight: 700;
      line-height: 1.6;
    }
    .nomination:after {
      content: "Nominated";
    }
  }
}

@media (min-width: 992px) {
  .awards__block--overlay {
    font-size: 1.1em;
  }
}

@media (min-width: 768px) {
  .awards__block--overlay .close-icon {
    top: 1.25em;
    font-size: 2em;
  }
}

.awards__block--overlay--dark {
  background: hsla(0, 0%, 7%, 0.95);
}

.awards__block--overlay .awards__container {
  width: 40em;
  width: 100%;
  max-width: 100%;
  max-height: 80vh;
  margin: 0 auto;
  text-align: center;
  overflow: scroll;
}

@media (min-width: 768px) {
  .awards__block--overlay .awards__container {
    max-height: 90vh;
  }
}