html, body {
	font-size: 1em;
	color:$brand-black;
	font-family: 'museo-sans-rounded', 'helvetica-neue', helvetica, arial, sans-serif;
	height:100%;
	@include fontSmoothing();
}

body {
	// padding-top: 0;
	// transition: all .4s ease;
	&.attached {
		padding-top: 4.0625em; //65px
	}
}

a {
	transition: all .3s ease;
	&.db {
		display: block;
		margin: 54px 0 0 0;
	}
}

video {
	object-fit:cover!important;
}

.videoWrapper {

	position: relative;
	height: 0;
	overflow: hidden;

	.flex--video & {
		height:100%;
		width:100%;
		position: absolute;
		top:0;
		left:0;
		&:after {
			content:"";
			bottom:0;
			left:0;
			height:100%;
			display: block;
			position:absolute;
			z-index: 9;
			width:100%;
	    	background: linear-gradient( 180deg, rgba(0,0,0,0.0), rgba(0,0,0,0.9) );
		}
	}

	.media-container & {
		padding-bottom: 56.25%; // standard 16:9
	}
	
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// object-fit: cover;
		// height:45em;
	}

}

.media-container {
	margin-bottom: 1.25em;
}

video, object, embed, iframe {
	width:100%;
}

.wf-loading {
	h1,h2,h3,h4,h5,h6,p,a,img,ul {
		opacity:0;
	}
}

.wf-active {
	h1,h2,h3,h4,h5,h6,p,a,img,ul {
		opacity:1;
		transition: all .5s ease;
	}
}

.b-lazy {
    -webkit-transition: opacity 500ms ease-in-out;
       -moz-transition: opacity 500ms ease-in-out;
         -o-transition: opacity 500ms ease-in-out;
            transition: opacity 500ms ease-in-out;
             max-width: 100%;
               opacity: 0;
}

.b-lazy.b-loaded {
               opacity: 1;
}

.generic-btn {
    float: left;
    padding: .75em;
    // background: #cbd1d4;
    color: #333;
    display: inline-block;
    border-radius: 2px;
    font-weight: 700;
    margin-top: 1em;
    font-size: .9em;
	border-top: 1px solid #333;
	border-bottom: 1px solid #333;
    &:hover,
    &:focus {
    	color:#fff;
    	background: $brand-orange;
    }
}