.primary-about-column p:first-of-type::first-letter {
		color:$brand-orange;
		@include heavyText();
		font-size: 3.5em;
		vertical-align: baseline;
		padding:0;
		font-family: Times;
		line-height: 1;
		float: left;
		padding: 0 .03em 0 0;
		margin-bottom: -.2em;
		@media (min-width:$screen-sm-min) {
			font-size: 6em;
			// padding: 0 .035em 0 0;
			line-height: .825;
			margin-bottom: -.05em;
		}
}

p {
	
	@include lightText();
	line-height:1.65;
	font-size: 1.125em;
	
	+ h6 {
		color:#777;
	}

	&[data-member]:first-letter {
		color:$brand-orange;
		@include heavyText();
		font-size: 3.5em;
		vertical-align: baseline;
		padding:0;
		font-family: Times;
		line-height: 1;
		float: left;
		padding: 0 .03em 0 0;
		margin-bottom: -.2em;
		@media (min-width:$screen-sm-min) {
			font-size: 6em;
			// padding: 0 .035em 0 0;
			line-height: .825;
			margin-bottom: -.05em;
		}
	}

	&.active, &.inactive {
		transition: all .3s ease, transform .5s ease, max-height .3s ease;
		opacity:1;
		max-height: 50em;
		@media (max-width:$screen-sm-min) {
			max-height: initial;
		}
	}

	&.inactive {
		opacity:0;
		transition: opacity 0s ease;
		max-height: 0;
		transform:translateY(1em);
	}

	&.active {
		order:-1;
		transform:translateY(0);
	}

	&.lead {
		font-size: $font-size-h4;
		@media (min-width:$screen-sm-min) {
			font-size: 1.375em;
		}
	}

	&:empty {
		display: none;
	}

	// very explicit p style for front page only.
	.home.page .section--content & {
		color:$light-copy2;
		font-size: 1.35em;
		line-height: 1.3;
	}
	
}

a {
	&:hover,
	&:focus {
		text-decoration:none;
		color:$brand-orange;
	}
}

.link {

	&--lines {
		padding: .75em 0;
		display: inline-block;
		margin-top: .75em;
		margin-bottom: .75em;
		font-size: .95em;
		border-top: 1px solid $brand-black;
		border-bottom: 1px solid $brand-black;
		color:$brand-black;
		text-transform: uppercase;
	}

}

.slide-link {
	transition: none 0s ease;
}

.slide-link:hover {
	transition:background-position 0s ease;
    background: linear-gradient($white,$white),linear-gradient($white,$white),linear-gradient($brand-orange,$brand-orange);
    background-size: .05em 1px,.05em 1px,1px 1px;
    background-repeat: no-repeat,no-repeat,repeat-x;
    text-shadow: .03em 0 $white,-.03em 0 $white,0 .03em $white,0 -.03em $white,.06em 0 $white,-.06em 0 $white,.09em 0 $white,-.09em 0 $white,.12em 0 $white,-.12em 0 $white,.15em 0 $white,-.15em 0 $white;
    background-position: 0 95%,100% 95%,0 95%;
}

.not-slide-link:hover {
	transition:background-position 0s ease;
    background: linear-gradient($brand-orange,$brand-orange),linear-gradient($brand-orange,$brand-orange),linear-gradient($brand-orange,$brand-orange);
    background-size: .05em 2px,.05em 2px,2px 2px;
    background-repeat: no-repeat,no-repeat,repeat-x;
    // text-shadow: .03em 0 $white,-.03em 0 $white,0 .03em $white,0 -.03em $white,.06em 0 $white,-.06em 0 $white,.09em 0 $white,-.09em 0 $white,.12em 0 $white,-.12em 0 $white,.15em 0 $white,-.15em 0 $white;
    background-position: 0 95%,100% 95%,0 95%;
}

@media (min-width:$screen-sm-min) {
	.red-slide-link {
	    display: inline-block;
	    position: relative;
	    padding-bottom: 3px;
		&:before {
		    content: '';
		    display: block;
		    position: absolute;
		    left: 0;
		    bottom: 0;
		    height: 3px;
		    width: 0;
		    transition: width 0s ease, background .5s ease;
		}
		&:after {
		    content: '';
		    display: block;
		    position: absolute;
		    right: 0;
		    bottom: 0;
		    height: 3px;
		    width: 0;
		    background: $brand-orange;
		    transition: width .5s ease;
		}
		&:hover:before {
		    width: 100%;
		    background: $brand-orange;
		    transition: width .5s ease;
		}
		&:hover:after {
		    width: 100%;
		    background: transparent;
		    transition: all 0s ease;
		}
	}
}

#select-filter {
    width: 100%;
    padding: .25em 0;
    border-radius: 0;
    border: 0;
    margin: 0 0 .5em;	
}

#anchor-filter-list {
	display: none;
	@media (min-width:$screen-md-min) {
		display: block;
	}
}

.list {

	&.credits-list {
		a {
			line-height: 2;
			display: inline-block;
			color:#111;
			transition: all .4s cubic-bezier(0.860, 0.000, 0.070, 1.000);
			span {
				display: inline-block;
				min-width: 135px;
			}
			&:hover,
			&:focus {
				color:$brand-orange;
				margin-left: 1em;
			}
		}
	}

	list-style-type: none;
	padding:0;	

	&--categories, &--productions {

		text-transform: uppercase;
		margin-bottom: 1.1em;
	
		li {
			display: inline-block;
			margin-left: -.25em;

				&.search-form-list-item {
					display: block;
					margin:.5em 0;
					@media (min-width:$screen-md-min) {
						margin:0;
						display: inline-block;
					}
				}

			a {
				color:$brand-black;
				padding:.15em 1em;
				border-right: 1px solid #ddd;
				@include heavyText();
				&:hover,
				&:focus {
					color:$brand-red;
				}
			}
			&:first-of-type {
				margin-left: 0;
				a {
					padding:.15em 1em 0 0;
				}
			}
			&:last-of-type {
				a {
					padding:.15em 1em;
					border-right:0;
				}
			}
			&.current-cat a {
				color:$brand-red;
			}
		}

	} // categories, productions

	&--productions {
		font-size: .97em;
		margin-bottom: 2em;
		li {
			margin-bottom: .5em;
			&.active {
				a {
					color:$brand-black;
				}
			}
			a {
				color:$light-copy;
			}

		}

	}

	&--staff {
		
		@media (max-width: $screen-sm-min) {
		    text-align: justify;
		}
	
		li {
			margin-bottom: .5em;
		    display: inline-block;
		    width: 44%;
		    margin-bottom: 2em;
		    vertical-align: top;
		    font-size: .8em;
		    margin-right: 1em;
		    word-break: break-word;

			@media (min-width:$screen-xs-min) {
				width: 30%;
			}

			@media (min-width:$screen-sm-min) {
				width: 100%;
				font-size: 1em;
				margin-bottom: .75em;
			}			
			
			&.active a {
				color:$brand-black;
				&:before {
					display: block;
					content:"";
					width:100%;
					height:3px;
					background: $brand-orange;
				}
			}

			a {
				color:$brand-grey;
				&:hover,
				&:focus {
					color:$brand-black;
				}
				@include heavyText();
				span {
					@include lightText();
					display: block;
				}
			}

		} // li

	} // staff

}

.production-text-title {
	margin:0 0 .25em;
}

::-moz-selection {
	color:$white;
	background: rgba($brand-orange, .8);
}
::selection {
	color:$white;
	background: rgba($brand-orange, .8);
}

select {
	text-transform: uppercase;
}

body[class*="-policy"] {
	
	.section--content {
	
		ul, ol {
			font-size: 1.125em;
			line-height: 1.65;
		}
	
	}
}