$team-red: #ce102c;
$team-orange: #d47f00;
$team-grey: #a2aaad;
$team-darkgrey: #323f48;

.staff-members {
	padding: 0 15px;
}

.list.line {
	
	margin-bottom: 2.5em;
    width: 100%;
    display: block;
    margin: 0 auto 1rem;
    max-width: 320px;
    vertical-align: top;
    text-align: center;
    transition: all .2s ease;
    
	@media (min-width: $screen-sm-min) {
		width: 30%;
		margin: 0 1% 0;
		display: inline-block;
	}

	li {
		color: #fefefe;
	    vertical-align: top;
	    max-width: 100%;
	    display: block;
	    margin: 0 auto;
	}
	
}

.team-member-card {
	font-size: 1.15em;
    padding: 2em 1em;
    clear: both;
    position: relative;
    display: inline-block;
    color: #fefefe;
    font-weight: 700;
    transition: all .3s ease;

    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: center;

	background: #333;
    
    span {
    	display: block;
    	font-weight: 500;
    	color:$white;
    	font-size: 1rem;
    }

    &:hover,
    &:focus {
    	color:#fefefe;
    	transform:scale(1.05);
	    background: #333;
	    color: #fefefe;
	    z-index: 1000;
    }

	&--red {
		background:$team-red;
		&:hover,
		&:focus {
			background: darken($team-red, 5%);
		}
	}
	&--orange {
		background:$team-orange;
		&:hover,
		&:focus {
			background: darken($team-orange, 5%);
		}
	}
	&--grey {
		background:$team-grey;
		&:hover,
		&:focus {
			background: darken($team-grey, 5%);
		}
	}
	&--darkgrey {
		background:$team-darkgrey;
		&:hover,
		&:focus {
			background: darken($team-darkgrey, 5%);
		}
	}
}

.team-member-single-description {

	h1 {
		margin:0 0 .5em;
	}

	h3 {
	    border-top: 1px solid #333;
	    padding-top: .5em;
	    margin: .5em 0 .6em;
	    color: $brand-orange;
	}

	p {
	    font-size: 1em;
	    border-bottom: 1px solid #333;
	    padding-bottom: 1.25em;
	}


	&.credits {
		padding-bottom: .5em;
		border-bottom: 1px solid #333;

		@media (min-width: $screen-sm-min) {
			margin-top: 3.55em;
			padding-bottom: 6.5em;
		}

		@media (min-width: $screen-md-min) {
			padding-bottom: 1.6em;
		}

	    @media (min-width: $screen-lg-min) {
	    	padding-bottom: 0;
	    }

	    .production-thumb {
			width: 25px;
			height: 25px;
			border-radius: 50px;
			vertical-align: top;
			margin-right: .25em;
			vertical-align: middle;
	    }

	    h3 {
	    	color:#333;
	    	font-weight: 500;
	    	border:none;
	    	@media (min-width: $screen-sm-min) {
				border-top: 1px solid #333;
	    	}
	    }

	}


}