

.sidebar-contact {

	@media (min-width: $screen-lg-min) {
		padding-left: 20%;
	}

	h1 {
		font-size: $font-size-h3;
		margin-top: 0;
	}

	p, a, address {
		color:$text-color;
	    margin-bottom: .625em;
	    font-style: normal;
	    line-height: 1.42857;
	    @include heavyText();
	    font-size: .875em;
	}

	address {
		margin:1em 0;
	}

	a {
		display: block;
		&:hover,
		&:focus {
			color:$brand-orange;
		}
		i {
			color:$brand-orange;
			font-size: 1.2em;
			vertical-align: bottom;
			margin-right: .25em;
		}
	}

	p {
		@include lightText();
	}

}

.news-block-section {
	clear:both;
}

.single-production {

	.entry-content {
		p {
			font-size: 1em;
			color:$light-copy2;
			strong {
				color:$brand-black;
			}
			&:first-child {
				color:$brand-black;
				font-size: 1.1em;
				line-height: 1.5
			}
		}
		&--two {
			p:first-child {
				font-size: 1em;
				line-height: 1.65;
				color:$light-copy2;
			}
		}
	}

}