.content-info {

	background: #f5f5f5;
	border-top: 1px solid #eee;
	padding:$global-padding;

	p, a, address {
		color:$text-color;
	    margin-bottom: 22px;
	    font-style: normal;
	    line-height: 1.42857;
	    @include heavyText();
	    font-size: 0.875em;

	}

	.social-footer {
		@media (max-width: $screen-sm-min) {
			margin-top: 1em;
		}
		.social-link {

		    font-size: 1.5em;
			margin-right: .5em;
			// margin-left: 0;
			@media (min-width:$screen-md-min) {
				margin-left: .5em;
				margin-right: 0;
			}
		    

		    &.imdb {
		    	margin-bottom: .5em;
		    	display: block;
		    	height:50px;
		    }
		}
		@media (min-width: $screen-sm-min) {
			text-align: right;
		}

	}

}