.dropdown
{
	line-height: 40px;
	margin-top: -5px;
	list-style:none;
	padding:0;
	margin:0;
	text-transform: uppercase;
	// margin-bottom: .5em;
	margin-bottom: 1em;
	-webkit-overflow-scroll:touch;
	overflow:auto;

	&.awards {
		display:block;
	    padding: 0 15px;
	    margin-top: 1.25rem;
	    @media (min-width:$screen-md-min) {
	    	display: none;
	    }
	}

	&.filter {
		.productions & {
			margin-bottom: 1.5em;
		}
		.about & {
			li {
				text-transform: initial;
				transition: color .3s ease;
				a {
				    border-bottom: 0;
				    line-height: 1.3;
				    margin-bottom: 8px;
				    &.title {
						margin-bottom: 0;
						&:before {
							content:"\f107";
							font-family: 'FontAwesome';
							display: inline-block;
							float: right;
							position: relative;
							top: 45%;
						}
						&:hover {
							color:$light-copy;
						}
						span {
							color:$brand-orange;
						}
				    }
					// &:hover {
					// 	span {
					// 		color:$brand-black;
					// 	}
					// }
				}
				span {
					border-bottom:0;
					padding-bottom: 5px;
					transition: all .3s ease;
				}
				&.active a {
					color:$brand-black;
					span {
						color:$brand-black;
					}
					border-bottom:2px solid $brand-orange;
				}
		    }
		}
	}

	@media (min-width: $screen-sm-min) {
		// margin-bottom: 1.75em;
		margin-bottom: 1.25em;
	}

	> li a.title {
		border-bottom: 0;
		// background: orange;
	}

	ul {
		padding:0;
	}
}

	.subject-dropdown
	{
		margin-top: 0;
		line-height: inherit;
	}
	
	.subject-dropdown ul
	{
		font-size: 14px;
		line-height: 20px;
		margin-top: -30px;
	}
	
		.subject-dropdown li
		{
			padding: 5px 0;
			border-bottom: 1px solid #ddd;
			color: #333;
			cursor: pointer;
		}
		
			.no-touch .subject-dropdown li:hover
			{
				color: #333;
			}
		
	.dropdown span,
	.dropdown a
	{
		color: #c2c2c2;
		display: block;
		border-bottom: 1px solid #ddd;
		&:hover,
		&:focus {
			color:$brand-black;
		}
	}
	
	.subject-dropdown span
	{
		border-bottom: 0;
	}
	
	.dropdown .title
	{
		// line-height: 2.25em;
		
	    line-height: 1;
	    padding-top: .25em;
	    padding-bottom: .5em;
	    @include heavyText();
		.fa {
			float: right;
			// top:10px;
			top:0;
			position: relative;
		}
		&:hover {
			color:$brand-black;
		}
	}
	
		.no-touch .dropdown:hover .title,
		.no-touch .dropdown a:hover,
		.no-touch .dropdown a:visited:hover
		{
			color: #333;
		}
		
		/*.no-touch .open .title
		{
			background-position: right -40px;
		}*/
	
	.dropdown ul
	{
		height: 0;
		overflow: hidden;
		list-style-type: none;
		// li {
		// 	float: left;
		// 	width:50%;
		// }
	}
	
		/*.no-touch .dropdown:hover ul,*/
		.dropdown .open
		{
			height: auto;
		}
	

#teamfilter {
	.active {
		@media (max-width: $screen-sm-min) {
			display: none!important;
		}
	}
}