// Glyphicons font path
$icon-font-path:        "../fonts/";
$global-padding:		2.75em 0;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;

$overlay-blue:			#333F46;

// $brand-red:				#DA1A32;
// $brand-orange:			#DB821F;
$brand-orange:			#ff8400;
$brand-red:				$brand-orange;

$brand-borange:			#FF8300;
$brand-yellow:			#FFD600;

// $brand-black:			#231F20;
$brand-deep-black:		#111;
$brand-black:			#323e48;
$brand-blue:			#1C2B39;
$brand-grey:			#A1ABB2;
$brand-bgrey:			#D8DCDB;

$light-copy:			#999999;
$light-copy2:			#777777;
$footer-grey:			lighten($brand-grey, 15%);

$white:					#fff;

@mixin lightText {
	font-weight: 300;
}
@mixin medText {
	font-weight: 500;
}
@mixin heavyText {
	font-weight: 700;
}
@mixin fontSmoothing {
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;	
}

$font-size-h1:            2.25em; // ~36px
$font-size-h2:            1.875em; // ~30px
$font-size-h3:            1.5em; // ~24px
$font-size-h4:            1.125em; // ~18px
$font-size-h5:            1em;
$font-size-h6:            0.75em; // ~12px

@import "common/bsvars";