.media-container {

	@media (max-width:$screen-md-min) {
		margin-top: 1em;
	}

}

.content-slide {

	&--dark {
		color:$white;
	}
	
	&--light {
			color:$brand-black;	
	}

	&--video {
		.video {
			position: relative;
			z-index: 10;
		}

		video {
			width:100%;
			height: 100%;
		}
	}

	position: relative;

	color:$white;
	background:$brand-black;
	font-size: 1.2em;

	&:after {
		content:"";
		bottom:0;
		left:0;
		height:100%;
		display: block;
		position:absolute;
		z-index: 9;
		width:100%;
    	background: linear-gradient( 180deg, rgba(0,0,0,0.0), rgba(0,0,0,0.9) );
	}

	h1 {
		@include heavyText();
		margin-bottom: 0;
		// font-size: 3em;
		font-size: 2.5em;
		line-height: 1.1;
		// text-align: center;
		@media (min-width:$screen-sm-min) {
			text-align: left;
			max-width: 82%;
			font-size: 3em;
		}
		.single-production & {
			text-align: center;
			@media (min-width:$screen-sm-min) {
				text-align:left;
			}
		}
	}

	.lead {
		margin-bottom: 1em;
		font-size: 1.1em;
		margin-bottom: 3.75em;
		// text-align: center;
		@media (min-width:$screen-sm-min) {
			text-align: left;
			margin-bottom: 2.15em;
		}
		@media (min-width:$screen-md-min) {
			margin-bottom: 2.5em;
		}
	}

	.slickify--single-production & {
		h1 {margin-bottom: 1.6em;}
	}

	.slickify--aboutpage & {
		
		h1 {
			// color:$brand-red;
			margin-bottom: .15em;
		}

		p {
			font-size: 1.1em;
			max-width: 32em;
			margin-bottom: 2.5em;
			line-height: 1.3;
			// @media (max-width:$screen-md-min) {
			// 	text-align: center;
			// }
		}

		&:after {
			height:100%;
		}
		
	}

	a {
		color:$white;
		&:hover,
		&:focus {
			color:inherit;
		}
	}

	.flex {
		
		height:24em;
		display: flex;
	    align-content: space-between;
	    justify-content: flex-end;
	    flex-direction: column;
	    z-index: 100;
	    position:relative;

	    h1,p {
	    	opacity:0;
	    	transform:translateX(-20px);
	    	transition: all .5s ease;
	    }

    	&.desc-appear h1, &.desc-appear p {
    		opacity:1;
    		transform:translateX(0);
    	}

		@media (max-width:$screen-sm-min) {
			font-size: .8em;
		}

		@media (min-width:$screen-md-min) {
			height:26em;
		}

		@media (min-width:$screen-lg-min) {
			height:30em;
		}		

	}

}

.blog .post.hentry {
	@media (max-width:$screen-sm-min) {
		width:100%;
		.byline.author.vcard {
			padding-bottom:1em;
		}
	}
}

article {	

	&.article--news {
    	border-bottom: 2px solid $brand-orange;
    	&[style] {
    		width:100%!important;
    	}
		@media (min-width:$screen-sm-min) {
			min-height: 16em;
		}

		.section--sidebar & {
			font-size: .6em;
			min-height: 0;
			border-bottom:0;
			line-height: 1;
		}

		.entry-title {
			a {
				color:$brand-black;
				@include heavyText();
				// @include lightText();
				&:hover,
				&:focus {
					color:$brand-orange;
				}
			}
		}

		.byline.author {
			text-transform: uppercase;
			@include heavyText();
			font-size: 1em;
			margin:0;
			&.news-source {
				color:$brand-black;
			}
			.time {
				color:$light-copy2;
				&:before {
					content:"/";
					display: inline-block;
				    color: $brand-black;
				    margin-right: .25em;
				}
			}
		}

	}

	.single-post & {

		.single-post-hero {
			margin-bottom: 1.5em;
		}

		h1 {
    		margin: 0 0 .5em;
    		@include heavyText();
		}

		.entry-content {
			padding-bottom: 1em;
			border-bottom: 2px solid $brand-orange;
			margin-bottom: 1.5em;
			
			p a {
				color:$brand-black;
				font-weight: 700;
			    -webkit-transition: background-position 0s ease;
			    transition: background-position 0s ease;					
			    background: -webkit-linear-gradient(#fff,#fff),-webkit-linear-gradient(#fff,#fff),-webkit-linear-gradient($brand-orange,$brand-orange);
			    background: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff),linear-gradient($brand-orange,$brand-orange);
			    background-size: .05em 1px,.05em 1px,1px 1px;
			    background-repeat: no-repeat,no-repeat,repeat-x;
			    text-shadow: .03em 0 #fff,-.03em 0 #fff,0 .03em #fff,0 -.03em #fff,.06em 0 #fff,-.06em 0 #fff,.09em 0 #fff,-.09em 0 #fff,.12em 0 #fff,-.12em 0 #fff,.15em 0 #fff,-.15em 0 #fff;
			    background-position: 0 95%,100% 95%,0 95%;
			    &:hover,
			    &:focus {
				    color:$brand-orange;
			    }
			}

		}

	}

}

.section {

	&--slider {
		overflow: hidden;
		position: relative;
		.slick-dots {
			// bottom:1.5em;
			// bottom:3.1em;
			// padding-right: 1em;
			padding-right: 0;
			right:0;
			left:0;
			// bottom:1.25em;
			bottom:1.75em;
			width:100%;
			// text-align: center;
			width:auto;
			left:11px;
			text-align:left;

			@media (min-width:$screen-sm-min) {
				width:auto;
				padding-right: 1em;
				bottom:3.1em;
				left:initial;
			}

			li {
				width:10px;
				height:10px;
				button {
					width:10px;
					height:10px;					
					&:before {
						width:10px;
						height:10px;
					}
				}				
			}
			
			@media (min-width:$screen-md-min) {
				bottom:3.625em;
				// padding-right: 10%;
				padding-right: 5%;
			}			
		}
	}

	&--content {
		padding:$global-padding;
		.contact.page &, .about.page & {
			> h1, > h2, > h3, > h4 {
				margin-top: 0;
			}
		}
		.about.page & {
			h3 {
				margin-bottom: 1em;
				@media (min-width:$screen-sm-min) {
					margin-bottom: 1.25em;
				}
			}
		}
	}

	&--sidebar {
		.articles-block {
		    // margin: 1.3em 0 1em;
		    margin:1.2em 0 1em;
		    padding-bottom: .5em;
		    border-top: 1px solid $brand-black;
		    border-bottom: 1px solid $brand-black;
		    
			.blog &, .search & {
				margin:1.8em 0 1em;
			}
		}

		h2 {
			margin-bottom: .2em;
		}

		h5 {
			margin:0;
			padding:.15em 0 0;
			font-weight: 700;
			@media (max-width:$screen-sm-min) {
				margin-top: 1em;	
			}
			
		}

		footer h5 {
			margin-bottom: 1em;
		}

		.byline.author.vcard {
			font-size: 1.25em;
			margin:.25em 0;
		}

		&--notnews {

		  	display: none;

			@media (min-width:$screen-md-min) {
			    display: block;
			}

			h2 {
				margin-bottom: .7em;
				font-size: 1.15em;
				font-weight: 700;
				a {
				  	color: #323e48;
				  	&:focus, &:hover {
					    color: #ff8400;
					}
				}
			}
		}


	}


}

.entry-content {
	&--two {
		> h1, > h2,> h3,> h4,> h5,> h6,> p,> a {
			margin-top: 0;
		}
	}	
}

.staff-role-description {
    display: flex;
    flex-direction: column;	
}

/* Share Block -- News Single */

.post-block {
    padding-top: 1.5em;
    border-top: 1px solid $brand-black;
    margin-top: 2.45em;
    padding-top: 1.8em;
		.single-post & {
			@media (max-width:$screen-sm-min) {
				margin-top: 0;
			}
		}
}

.post-meta {
	border-top: 1px solid $brand-black;
	margin-top: 2.45em;
	// padding-top: 1.8em;
	padding:1.5em 0;
	line-height:1.3;
	border-bottom: 1px solid $brand-black;

	.single-post & {
		@media (max-width:$screen-sm-min) {
			margin-top: 0;
		}
		&.visible-xs {
			border-bottom: 1px solid $brand-black;
			border-top: 0;
			padding-top: 0;			
		}
	}
	
	// alternative style
	.single-production & {
		margin-top: 0;
		// padding-top: .75em;
		padding:1.5em 0;
	}

	+ .post-meta {
		margin-top: 0;
		border-bottom:1px solid $brand-black;
		border-top: 0;
		.single-production & {
			@media (max-width:$screen-sm-min) {
				border-bottom:0;
			}
		}		
		.single-post & {
			@media (max-width:$screen-sm-min) {
				display: none;
			}
		}
	}

	.social-link {
		font-size: 1.5em;
		margin-right: .25em;
		// color:$brand-orange;
		color:$brand-black;
	}
	p,.span {
		@include heavyText();
		text-transform: uppercase;
		margin-bottom: .1em;
		.single & {
			line-height: 1.4;
		}
	}
	.time {
		display: block;
		color:$light-copy;
	}
}

.col-sm-6:nth-of-type(2) .inner.border-top {
	border-top: 0;
	@media (min-width:$screen-sm-min) {
		border-top: 1px solid $brand-black;		
	}
}
.col-sm-6:nth-of-type(3) .inner.border-top {
	border-top: 1px solid $brand-black;
}

