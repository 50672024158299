.navbar {

		// background: #efefef;
		text-transform: uppercase;
		border-bottom: 0;
		margin-bottom: 0;
		border-radius: 0;
		box-shadow: 0 0 10px rgba(0,0,0,0);
		transition: box-shadow .4s ease, background .4s ease;

		border-bottom: 1px solid #eee;
		background: #f5f5f5;

		&.attached {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 10001;
			box-shadow: 0 0 10px rgba(0,0,0,0.1);
			background: #fefefe;
		}

		.navbar-toggle {
			// margin-top: .9em;
			margin-top: .65em;
			.icon-bar {background: $brand-orange;}
		}

		.home.page &, .contact.page &, .about.page &, .single-production & {
			background:$white;
			border-bottom:none;
		}

		@include heavyText();

		.forgetv_brand {

			.cls-1 {
				fill:$brand-orange!important;
			}
			
			@media (max-width:$screen-sm-min) {
				margin:.6em 0;
				margin-left: .9em;
			}

			width:11.5em;
			height:auto;
			margin:.8em 0;
			
		}

		.nav {

			margin-bottom: 0;

			li {

				&.active {
					a {
						color:$brand-orange;
					}
					a:after {
						// bottom:-2px;
						bottom:0;
						opacity:1;
					}
				}

				a {
					line-height: 1;
					color:$text-color;
					border-bottom:1px solid #f5f5f5;

					@media (min-width: $screen-sm-min) {
						border-bottom:none;
						line-height: 2;
						margin:0 .7em;
						padding:1.1em 0;						
					}

					@media (min-width: $screen-md-min) {
							margin:0 1em;
					}

					&:after {
						content:"";
						width:100%;
						height:2px;
						background:$brand-orange;
						position: absolute;
						bottom:-5px;
						left:0;
						transition: all .3s ease;
						opacity:0;
						display: none;
						@media (min-width:$screen-sm-min) {
							display: block;
						}
					}		

					&:hover,
					&:focus {
						background:transparent;
						color:$brand-orange;
						position: relative;
						@media (max-width:$screen-sm-min) {
							background: #f5f5f5;
						}
						&:after {
							// bottom:-2px;
							bottom:0;
							opacity:1;
						}
					}

				}

			}

		}

}