// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($line-height-computed / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($line-height-computed / 2);
  height: auto;
}
@media (min-width: $screen-sm-min) {
  // Only float if not on an extra small device
  .alignleft {
	float: left;
	margin-right: ($line-height-computed / 2);
  }
  .alignright {
	float: right;
	margin-left: ($line-height-computed / 2);
  }
}

// Captions
.wp-caption {
  @extend .thumbnail;
}
.wp-caption-text {
  padding: $thumbnail-caption-padding;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}


// wp posts navigation styles

.posts-navigation {
	padding:1.25em .75em .25em;
	@include clearfix();
	  @media (min-width:$screen-sm-min) {
		padding:2em 1em 0;
	  }
	.nav-links {
		@include clearfix();
		a {
			&:first-child {
				margin-right:.75em;
			}
			float: left;
			padding:.75em;
			background:lighten($brand-grey, 15%);
			color:$white;
			display: inline-block;
			border-radius:2px;
			font-weight: 700;
			@media (max-width:$screen-sm-min) {
				font-size: .8em;
			}
			&:hover,
			&:focus {
				background:$brand-orange;
			}
		}
	}
}
