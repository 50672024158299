.banner {

	.slide-out-search {
		@media (min-width:$screen-md-min) {
			padding-bottom: 1.15em;
		}
	}

	.search-display-btn {
	    display: inline-block;
	    padding: 0 .75em 0 0;
	    text-align: center;
	    font-size: 1.25em;
	    position: absolute;
	    left: 0;
	    top:0;
	    background: transparent;
	    transition: all .3s ease;
	    // background: $white;
	    border: none;
	    z-index: 10000;
	    line-height: 1.1;
	    @media (min-width:$screen-md-min) {
	    	padding: 0;
	    }
	}

	.form-group {
	    position: relative;
	    padding-bottom: 1em;
	    margin: 0;
		@media (min-width:$screen-md-min) {
			padding-bottom: .45em;
		}
	}

	input {
		transition: all .3s ease;
		overflow: hidden;
		opacity:0;
		pointer-events:none;
		border-radius: 2px;
		background: #f5f5f5;
		border: 0;
		margin: 0;
		padding: 0;
		width: 100%;
		font-size: 1em;
		position: relative;
		left: .25em;
		
		@media (max-width: $screen-xs-min) {
	    	z-index: 999;
	    	// opacity:1;
	    	pointer-events:all;
	    	overflow: visible;
		}

		@media (min-width:$screen-xs-min) {
			left: .5em;
		}

	    &.display-search {
			z-index: 999;
			opacity: 1;
			pointer-events: all;
			overflow: visible;
			// padding: .9em;
			padding: .85em 1em .8em 2.75em;
			border-bottom:2px solid $brand-orange;
			left: 0;

			+.search-display-btn {
				top:.65em;
				left:.75em;
				padding:0;
			}
	    }	

		&:focus {
			outline:none;
		}
	}

}